"use strict";
var Cookie = require('cookies');

/**
 * Session object is a way to tag user's movement and
 * behavior in using the site
 *
 * @constructor
 */
function Session()
{
    this.canTrack = (Cookie.enabled);
    this.userID = null;
}

/**
 * Initializes the session
 */
Session.prototype.start = function ()
{

    if (this.canTrack) {
        this._createNewUser();
        this._createNewSession();
    }
};

/**
 * Returns the user session value
 */
Session.prototype.get = function ()
{
    return this.userID;
};


/**
 * Returns the current session
 *
 * @returns {*}
 */
Session.prototype.getCurrentSession = function ()
{
    return Cookie.get('_s');
};

/**
 * Creates a new session for the user
 * if the user hasn't created or visited the site yet.
 */
Session.prototype._createNewSession = function ()
{
    // creates a random session id
    var sessionID = '#xxxxxxxx-xxxx'
        .replace(/[xy]/g, function (c)
        {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });

    // create one
    Cookie.set('_s', sessionID);
};


/**
 * Assign a unique session to identify
 * the user using the site.
 */
Session.prototype._createNewUser = function ()
{
    // no user id found
    var userID = Cookie.get('_u');

    if (!userID) {

        // creates a random user id
        userID = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
            .replace(/[xy]/g, function (c)
            {
                var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });

        // create one
        Cookie.set('_u', userID);
    }

    this.userID = userID;
};


module.exports = Session;