"use strict";
var Wiper = require('wiper');

function CatchAllRouteHandler()
{
}

CatchAllRouteHandler.prototype.init = function ()
{
    var wiper = new Wiper();
    wiper.wipeIn();
};

module.exports = CatchAllRouteHandler;