"use strict";
var _ = require('lodash');
var $ = require('jquery');

/**
 * The role is to track user behavior in the site
 * understanding their browsing pattern
 *
 * This simplifies the whole process of tracking various
 * items on the site.
 *
 * @constructor
 */
function Tracker()
{
    this.buffer = null;
    this.session = null;
    this.mouse = null;
    this.analytics = null;
}

/**
 * Starts tracking the user
 */
Tracker.prototype.start = function ()
{
    this.session.start();
    this.buffer.init();
    this.mouse.track();

    /*------------------------------------------------------------
     Resize events
     ------------------------------------------------------------*/
    this.$window = $(window);
    this.$window
        .on('resize', _.debounce(this._onResize.bind(this), 100));
};

Tracker.prototype._onResize = function ()
{
    // trigger a resize
    this.buffer.add({
            w: this.$window.width(),
            h: this.$window.height()
        },
        '_r'
    );
};

/**
 * Add track data into the buffer
 *
 * @param data
 */
Tracker.prototype.track = function (data)
{
    this.buffer.add(data);
};

module.exports = Tracker;