"use strict";

var _ = require('lodash');
var defaultMiddleware = require('middleware/default');

/**
 * Maps the route into their corresponding handler.
 *
 * @param router
 * @param routes
 * @constructor
 */
function RouteMapper(router, routes)
{
    this.router = router;
    this.routes = routes;
}

/**
 * Binds the mapped into the router request handler
 *
 * @param container
 * @param app
 */
RouteMapper.prototype.bindRoutes = function (container, app)
{
    this.app = app;
    this.container = container;

    this._mapRoutesToHandler(this.routes);

    /*------------------------------------------------------------
     Process each of the end points
     ------------------------------------------------------------*/
    var router = this.router;

    _.each(this.routes, function (cb, route)
    {
        router.get(route, function (req, e, next)
        {
            defaultMiddleware(req, this);
            next();
        }, cb);
    });
};

/**
 * Maps the corresponding routes to their specific handler
 * @param mapping
 * @returns {*}
 * @private
 */
RouteMapper.prototype._mapRoutesToHandler = function (mapping)
{
    _.each(mapping, this._iterateRouteHandler.bind(this));

    return mapping;
};

/**
 * Iterates to each route and build the neccessary call back to be called
 * when the route is loaded
 *
 * @param handler
 * @param route
 * @param mapping
 * @private
 */
RouteMapper.prototype._iterateRouteHandler = function (handler, route, mapping)
{
    var h = require('route-handler/' + handler);
    h = new h();

    // make these variables accessible to the route handler
    h.app = this.app;
    h.container = this.container;
    h.router = this.router;

    // For tracking
    h.tracker = this.container.get('tracker');

    mapping[route] = function (req)
    {
        h.req = req;
        h.init();
    };

};


module.exports = RouteMapper;