"use strict";

var $ = require('jquery');
var _ = require('lodash');

/**
 * Default middleware use by the site
 *
 * @param req
 * @param router
 */
module.exports = function (req, router)
{

};