"use strict";
var $ = require('jquery');
var _ = require('lodash');
var Meta = require('meta');

var Scroller = require('scroller');
var ScrollerHandler = require('scroller-handler');
var Wiper = require('wiper');

/**
 * Route handler for /home
 *
 * @constructor
 */
function HomeRouteHandler()
{
    this._meta = {
        title      : ' - a Manila-based interaction design agency.',
        description: 'Frost is a Manila-based design and consulting agency ' +
        'with a focus on creating handcrafted digital experiences.',
        url        : '/'
    };
}

/**
 * Initializes page
 *
 * @private
 */
HomeRouteHandler.prototype.init = function ()
{
    Meta.set(this._meta);

    /*------------------------------------------------------------
     Detects how far the cards from the top
     use for handling what segments should be shown to the
     user when scrolling.

     Use for transitioning cards on enter
     ------------------------------------------------------------*/

    this.cardScrollEntryHandler = new ScrollerHandler({
        el    : '.card',
        handle: function (el)
        {
            el.addClass('enter');
        },
        // show after 90% of the screen
        offset: 0.9
    });

    /*------------------------------------------------------------
     Detects what cards are visible to the user.
     Use for tracing
     ------------------------------------------------------------*/
    var tracker = this.tracker;
    var currentProjectOnView = '';
    this.cardScrollLogHandler = new ScrollerHandler({
        el         : '.card',
        handleMatch: function (els)
        {
            var projects = _.map(els, function (el)
            {
                return el.data('name');
            }).join(', ');

            if (currentProjectOnView !== projects) {
                currentProjectOnView = projects;
                tracker.track({v: currentProjectOnView}, '_e');

                window.ga('send', 'event',
                    'Live View from Scroll',
                    'scroll',
                    currentProjectOnView);
            }
        }
    });

    this.app.set('projects', this.app.projectDataManager.getProjects());
    this.app.set('view', 'home')
        .then(this._onSet.bind(this));

};

/*------------------------------------------------------------
 EVENT HANDLER
 ------------------------------------------------------------*/

HomeRouteHandler.prototype._handleEvents = function ()
{
    /*------------------------------------------------------------
     Handles scroll event
     ------------------------------------------------------------*/
    Scroller.scroll({
        onscroll: this._onScroll.bind(this)
    });
};

/*------------------------------------------------------------
 OnScroll Event
 ------------------------------------------------------------*/
HomeRouteHandler.prototype._onScroll = function (scrollPosition, priority)
{

    // Make this scrolling event high priority
    if (priority === 'high' &&
        this.app.get('view') === 'home') {

        this.app.set('currentScrollPosition', scrollPosition);
        this.cardScrollEntryHandler.checkHit(scrollPosition);
        this.cardScrollLogHandler.checkHit(scrollPosition);

        this.tracker.track({y: scrollPosition}, '_s');
    }

};

/**
 * Called when the page is completely rendered
 *
 * @private
 */
HomeRouteHandler.prototype._onSet = function ()
{
    this._handleEvents();

    var currentScrollPosition = this.app.get('currentScrollPosition');

    // Make sure to make the first project visible
    this.cardScrollEntryHandler.checkHit(0);

    $(".project-experiences").on('click', function() {
        $('html, body').animate({
            scrollTop: $(".projects").offset().top
        }, 1500);
    });

    $(".nav > li > a").removeClass("-active");

    Scroller.scrollTo(currentScrollPosition);

    var wiper = new Wiper();
    wiper.wipeOut();
};

module.exports = HomeRouteHandler;