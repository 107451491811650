"use strict";
var UIControls = require('ui-controls');
var $ = require('jquery');

module.exports = function ()
{
    var ui = new UIControls();

    ui.toggleVisibility('.faq');

    var toggleValue = ($('.faq').hasClass('visuallyhidden')) ?
        'off' : 'on';

    var faqTiming = {
        startTime: 0
    };

    // FAQ timing details hasn't been set yet
    if (!this.root.get('_faq')) {

        //set default
        // The FAQ button has just been open
        this.root.set('_faq', faqTiming);
    }

    faqTiming = this.root.get('_faq');

    if (toggleValue === 'on') {
        faqTiming.startTime = Date.now();
    } else {
        var elapsedTime = Date.now() - faqTiming.startTime;
        this.root.unset('_faq');

        // send read time
        window.ga('send', 'timing',
            'FAQ', 'Read Time', elapsedTime,
            'FAQ Read time on Contact Page');
    }

    window.ga('send', 'event',
        'FAQ Button', 'toggle:' + toggleValue,
        'FAQ Button on Contact Page');
};