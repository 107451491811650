var _ = require('lodash');
var $ = require('jquery');

var UIControls = require('ui-controls');
var Scroller = require('scroller');
var Buffer = require('tracker/buffer');
var ScrollerHandler = require('scroller-handler');
var Meta = require('meta');
var FormRenderer = require('form-renderer');
var Tabs = require('tabs');

/**
 * Sets up the route's end points
 * @param router
 * @param app
 */
module.exports = function (app, router)
{
    "use strict";

    var $window = $(window);
    var ui = new UIControls();
    var currentScrollTop = 0;


    /*------------------------------------------------------------
     List of routes
     ------------------------------------------------------------*/

    var routes = {

        // List all projects
        '/': function ()
        {
            var description = 'Rain is a Manila-based design and consulting agency ' +
                'with a focus on creating handcrafted digital experiences.';

            Meta.set({
                title      : 'We are Rain – a Manila-based web design agency.',
                description: description,
                url        : '/'
            });

            app.set('view', 'home').
                then(function ()
                {
                    Scroller.scrollTo(currentScrollTop);

                    if (currentScrollTop === 0) {
                        $('.projects > .card:first-of-type').addClass('enter');
                    }
                });
        },

        // View a particular project
        '/project/*': function (req)
        {
            // set the current project on view
            var project = app.getProject(req.matches[0]);
            app.set('project', project);

            Meta.set({
                title      : project.name,
                description: project.details.summary.preamble,
                url        : '/' + project.url,
                image      : 'data/projects/' + project.namespace + '/' + project.details.cover
            });

            app.set('view', 'project')
                .then(function ()
                {
                    Scroller.scrollTo(0);
                });

        },

        // About Us
        '/about': function ()
        {
            app.set('project', null);

            var description = 'Having almost 14 years of experience in the industry, ' +
                'we’ve built the confidence and rapport to deliver the most demanding ' +
                'projects but still maintain the level of quality in every step of the way.';

            Meta.set({
                title      : 'What we can do for you',
                description: description,
                url        : '/about'
            });

            var tabs = new Tabs({
                ontabclicked: function (tab, target, firedDuringInit)
                {
                    app.fire('tab-clicked', tab, target, firedDuringInit);
                }
            });

            tabs._init();

            app.set('view', 'about');
        },

        // Contact
        '/contact': function ()
        {
            app.set('project', null);

            Meta.set({
                title      : 'Get in touch',
                description: 'Let\'s talk about business!',
                url        : '/contact'
            });

            var tabs = new Tabs({
                ontabclicked: function (tab, target, firedDuringInit)
                {
                    app.fire('tab-clicked', tab, target, firedDuringInit);
                }
            });

            tabs._init();

            app.set('view', 'contact')
                .then(function ()
                {
                    //var formRenderer = new FormRenderer();
                    //formRenderer.render();
                });
        }
    };


    /*------------------------------------------------------------
     Process each of the end points
     ------------------------------------------------------------*/

    _.each(routes, function (cb, route)
    {
        router.get(route, cb);
    });

    /*------------------------------------------------------------
     Handle hashchange event
     ------------------------------------------------------------*/

    router.on('hashchange', function ()
    {
        //router.navigate(this.state.value);
        //Buffer.add({p: router.path()}, '_p');
    });
    //
    //Buffer.add({p: router.path()}, '_p');
};

