var $ = require('jquery');
var _ = require('lodash');

var SubmitButton = require('submit-button');

/**
 * Takes all form elements and render it
 * into a material like design form format
 * injecting the neccessary tags
 *
 * @constructor
 */
function FormRenderer()
{
    "use strict";
}

/**
 * Renders the form
 * */
FormRenderer.prototype.render = function ()
{
    "use strict";

    this.$visibleFormFields = $('.fields input[type!="hidden"],.fields textarea, .fields .services-dropdown-wrapper');
    _.each(this.$visibleFormFields, this.$renderField);

    // Renders the submit button
    this.submitButton = new SubmitButton();
};

/**
 * Get active fields in preparation for chainable methods
 *
 * @chainable
 * @param parent
 * @returns {FormRenderer}
 */
FormRenderer.prototype.getActiveFields = function (parent)
{
    "use strict";

    this.$activeFields = $('input, textarea, #service-type', $(parent));

    return this;
};

/**
 * Returns an array of fields base on the parent context
 *
 * getActiveFields
 *  .list();
 *
 * @returns {*|HTMLElement}
 */
FormRenderer.prototype.listActiveFields = function ()
{
    "use strict";

    return this.$activeFields;
};

/**
 * Reorder tab index
 * required a parent chain calling getActiveFields
 *
 * getActiveFields
 *  .reOrderTabIndex();
 *
 * @chainable
 * @return self
 */
FormRenderer.prototype.reOrderTabIndex = function ()
{
    "use strict";

    // maintain the active fields list
    var fields = _.clone(this.$visibleFormFields);
    fields = _.toArray(fields);

    fields.push(this.submitButton.$button);

    // remove all tab index
    $('input,textarea').removeAttr('tabindex');

    // apply a new tab index
    _.each(fields, function (field, i)
    {
        $(field).attr('tabindex', i + 1);
    });

    return this;
};

/**
 * Clear any error markings
 *
 * @returns {FormRenderer}
 */
FormRenderer.prototype.clearErrors = function ()
{
    "use strict";

    _.each(this.$visibleFormFields, function (field)
    {
        $(field).parent().removeClass('error');
    });

    return this;
};

/**
 * Clear any error markings
 *
 * @returns {FormRenderer}
 */
FormRenderer.prototype.clear = function ()
{
    "use strict";

    _.each(this.$visibleFormFields, function (field)
    {
        $(field).val('');
    });

    return this;
};

/**
 * Focus on a particular field base on the active fields
 * selected
 *
 * getActiveFields
 *  .focusOnField(n)
 *
 * @chainable
 * @param index
 * @returns {FormRenderer}
 */
FormRenderer.prototype.focusOnField = function (index)
{
    "use strict";
    $(this.$activeFields[index]).focus();

    return this;
};

/**
 * Renders the field
 *
 * @param field
 */
FormRenderer.prototype.$renderField = function (field)
{
    "use strict";

    field = $(field);
    field.wrap('<div class="field"></div>');

    var fieldParent = field.parent();

    // add the label
    fieldParent.prepend('<label>' + field.data('label') + '</label>');

    // Focus and blur event
    field
        .on('focus', function ()
        {
            fieldParent.addClass('focused');
        })
        .on('blur', function ()
        {
            fieldParent.removeClass('focused');
        });

    // Focus on the field when clicked
    fieldParent.on('click', function ()
    {
        $('input, textarea', this).focus();
    });

};

module.exports = FormRenderer;