var $ = require('jquery');
var _ = require('lodash');

module.exports = {
    scroll: function (options)
    {
        "use strict";
        var $window = $(window);
        var onScroll = options.onscroll;

        $window
            // fired every 16ms (60fps)
            .on('scroll', _.debounce(function (e)
            {
                var scrollTop = $(this).scrollTop();
                onScroll(scrollTop, 'high');
            }, 16))

            // fired every 100ms
            .on('scroll', _.debounce(function (e)
            {
                var scrollTop = $(this).scrollTop();
                onScroll(scrollTop, 'mid');
            }, 100))

            // fired every second
            .on('scroll', _.debounce(function (e)
            {
                var scrollTop = $(this).scrollTop();
                onScroll(scrollTop, 'low');
            }, 1000));
    },

    /**
     * Scroll to the target position
     * @param value
     */
    scrollTo: function(value) {
        "use strict";

        var $window = $(window);
        $window.scrollTop(value);
    }
};
