var $ = require('jquery');
var _ = require('lodash');

module.exports = {
    set: function (options)
    {
        "use strict";

        var opts = _.merge({
            url: '',
            title: '',
            description: '',
            image: 'assets/images/og.jpg'
        }, options);

        /*------------------------------------------------------------
         Title
         ------------------------------------------------------------*/
        $('meta[property="og:title"]').attr('content', opts.title);
        $('title').html('Frost' + opts.title);

        /*------------------------------------------------------------
         Description
         ------------------------------------------------------------*/

        $('meta[property="og:description"]').attr('content', opts.description);
        $('meta[name="description"]').attr('content', opts.description);

        /*------------------------------------------------------------
         Open Graph Image
         ------------------------------------------------------------*/

        $('meta[property="og:image"]').attr('content', 'http://www.raincreativelab.com/' + opts.image);

        /*------------------------------------------------------------
         URL
         ------------------------------------------------------------*/
        $('meta[property="og:url"]').attr('content', 'http://www.raincreativelab.com/#!' + opts.url);

    }
};