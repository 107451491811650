var $ = require('jquery');
var _ = require('lodash');
var isMobile = require('ismobile');

module.exports = {

    get: function() {
        "use strict";

        var $window = $(window);

        return {
            w: $window.width(),
            h: $window.height(),
            m: (isMobile.any)
        };
    }
};