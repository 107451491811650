"use strict";
var Container = require('container');
var Ractive = require('ractive');
var NProgress = require('nprogress');
var DeviceDetector = require('util/detector');

var $ = require('jquery');

var CheckScroll = require('util/checkScroll');
var lethargy = require('./libs/lethargy');
var Smart = require('./libs/smartscroll');

/* --------------------------------------------
 * Class wiring configuration file
 * --------------------------------------------*/
var wirings = require('./wirings.js');
var container = new Container(wirings);
var dd = new DeviceDetector();
var cs = new CheckScroll();

var ga = window.ga;

// var ssoptions = {
//     mode: "set", // "vp", "set"
//     autoHash: false,
//     sectionScroll: true,
//     initialScroll: true,
//     keepHistory: false,
//     sectionWrapperSelector: ".projects",
//     sectionClass: "card-sec",
//     animationSpeed: 300,
//     headerHash: "portfolio-container",
//     breakpoint: 999999,
//     eventEmitter: null,
//     dynamicHeight: false
// };

// Make the ractive app visible into global variables
container.build();

Ractive.prototype.unset = function (keypath)
{
    var parent   = '',
        property = keypath,
        lastDot  = keypath.lastIndexOf('.');

    if (lastDot !== -1) {
        parent = keypath.substr(0, lastDot);
        property = keypath.substring(lastDot + 1);
    }

    this.set(keypath);
    delete this.get(parent)[property];
    return this.update(keypath);
};

// Create a new ractive instance
var app = new Ractive({
    el      : '#portfolio-container',
    template: '#site-tpl',

    data: {

        // Current scroll position stored when the user
        // is in the home page. This maintains the value of the scroll
        // position when the user moves to different page.
        currentScrollPosition: 0,

        // The projects to be displayed
        // This has been pre-formatted already
        projects: {}

    },

    oninit: function ()
    {
        // Start the progress bar
        NProgress.start();

        /*------------------------------------------------------------
         Set and format the projects to be displayed
         ------------------------------------------------------------*/
        var projectDataManager = container.get('projectDataManager');
        projectDataManager.format();

        this.projectDataManager = projectDataManager;

        container.get('routeMapper').bindRoutes(container, this);
        container.get('eventMapper').mapEvents(container, this);

        var router = container.get('router');

        // Check if there is a hash bang detected in the URL
        if (!/\#/.test(document.URL)) {
            router.navigate('/');
        }

        /*------------------------------------------------------------
         Let's track the user's behavior inside the site
         ------------------------------------------------------------*/
        this.tracker = container.get('tracker');
        this.tracker.start();

        var tracker = this.tracker;

        /*------------------------------------------------------------
         Add class on body when in mobile
         ------------------------------------------------------------*/

        if (dd.detect().deviceType === 'tablet' ||
            dd.detect().deviceType === 'phone') {
            $('body').addClass('device');
        }

        // For google analytics tracking
        router.on('hashchange', function ()
        {
            tracker.analytics.trackPage();
        });


    },

    oncomplete: function ()
    {

        // Signify everything is done already
        NProgress.done();

        /*------------------------------------------------------------
         Initializes the message flasher
         ------------------------------------------------------------*/
        var messageFlasher = container.get('messageFlasher');
        messageFlasher.init(this);

        this.notify = messageFlasher;
        this.tracker.analytics.trackPage();

        /*------------------------------------------------------------
         Apply smart scroll
         ------------------------------------------------------------*/
        /* var div = $('#portfolio-container');
        if (div.length) {
          $.smartscroll(cs.detect().scroll);
        }

        var router = container.get('router');
        router.on('hashchange', function ()
        {
            if (div.length) {
              
                $.smartscroll(cs.detect().scroll);
                $(window).trigger('resize');
            }

        }); */
    }

});