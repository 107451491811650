"use strict";
var FormRenderer = require('form-renderer');
var _ = require('lodash');
var $ = require('jquery');

module.exports = function (context, data, container)
{
    var app = this;

    var dataSender = container.get('formDataSender');
    var formRenderer = app.formRenderer;

    var source = $(
        _.last(formRenderer.listActiveFields())
    ).val();

    //this assumes that formRenderer has an active field
    // set triggered by the tab-clicked event
    dataSender.submit(
        formRenderer.listActiveFields(),
        function ()
        {
            // Show the message to the user
            var messages = [
                "Great! Thanks, We'll get back to you.",
                "Yay! Cool. We'll check it out!",
                "Nice! You'll hear from us soon."
            ];

            var index = Math.floor(Math.random() * messages.length);

            formRenderer.submitButton.done(function ()
            {
                app.notify.flash(messages[index]);

                // Clear the fields
                formRenderer.clear();
                formRenderer.focusOnField(0);

                window.ga('send', 'event',
                    'Send Inquiry', 'click',
                    'Inquiry sent from:' + source);
            });

        }
    );
};