var $ = require('jquery');
var Buffer = require('tracker/buffer');

module.exports = {

    _init: function (router)
    {
        "use strict";

        $('input,textarea')
            .on('change', function ()
            {
                var field = $(this);
                var value = field.val();
                var name = field.attr('name');

                Buffer.add({e: 'change', f: name, v: value}, '_f');
            })
            .on('focus', function ()
            {

                var field = $(this);
                var name = field.attr('name');

                Buffer.add({e: 'focus', f: name}, '_f');

            });
    }
};
