"use strict";

var _ = require('lodash');
var $ = require('jquery');

function ScrollerHandler(options)
{
    var opts = {
        el    : null,
        handle: function () {},
        handleMatch: function() {},
        offset: 0
    };

    this.$options = _.merge(opts, options);
}

ScrollerHandler.prototype.checkHit = function (scrollTop)
{

    var els = $(this.$options.el);
    var handle = this.$options.handle;
    var handleMatch = this.$options.handleMatch;
    var scrollOffset = this.$options.offset;

    var $window = $(window);

    var matchEls = [];
    _.each(els, function (el)
    {
        el = $(el);

        var distanceFromTop = (el.position().top - scrollTop);

        // if the scrollOffset is less than 1 (decimal)
        // it uses is as percentage of the window's height
        var offset = (scrollOffset < 1) ?
        $window.height() * scrollOffset :
            scrollOffset;

        if (distanceFromTop <= offset) {

            var h = el.height();
            var visibleArea = h + distanceFromTop;

            // the area is visible
            if (visibleArea > 0) {
                handle(el);
                matchEls.push(el);
            }

        } else {
            return false;
        }
    });

    handleMatch(matchEls);
};

module.exports = ScrollerHandler;