"use strict";

var store = require('store');
var _ = require('lodash');
var environment = require('tracker/environment');
var compressor = require('lz-string');

var $tempBuffer = [];
var $backupBuffer = [];

/**
 * Buffer provides a way to store track data that
 * handles periodic collection and send off to the server for
 * permanent storage.
 *
 * @param session
 * @param request
 * @param router
 * @param enableTracing
 * @constructor
 */
function Buffer(session, request, router, enableTracing)
{
    this.stillSending = false;
    this.request = request;
    this.router = router;
    this.session = session;

    this.enableTracing = enableTracing;
}

/**
 * Initialize the buffer
 * It sends the buffer to the server if anything is available
 * and tracing is enabled
 *
 */
Buffer.prototype.init = function ()
{
    // send the value every second
    // If tracing is enabled
    if (this.enableTracing) {
        this.send();
    }
};

/**
 * Adds data into the buffer
 *
 * @param data
 * @param namespace
 */
Buffer.prototype.add = function (data, namespace)
{
    if (!this.enableTracing) {
        return;
    }

    // if data has no p value, add the current
    // router path
    if (!data.hasOwnProperty('p')) {
        data.p = this.router.path();
    }

    var session = this.session;

    this._getBuffer()
        .push({
            t : new Date().getTime(),
            d : data,
            u : session.get(),
            s : session.getCurrentSession(),
            e : environment.get(),
            ns: namespace
        });
};

/**
 * Sends the data into the server
 */
Buffer.prototype.send = function ()
{

    var that = this;
    this.stillSending = true;

    // don't run this anymore if there is no buffer found
    if ($tempBuffer.length === 0) {
        this.stillSending = false;
        this._waitAndSend();

        return;
    }

    var raw = JSON.stringify($tempBuffer);
    // time to compress the
    var compressed = compressor.compressToBase64(raw);
    var session = this.session;

    // request sent
    this.request.sendToServer(
        {_l: 'trace'},
        {
            _d: {
                c: compressed,
                s: session.get() + session.getCurrentSession()
            }
        },
        function ()
        {
            // the buffer was sent already
            // empty the buffer
            $tempBuffer.length = 0;
            // copy the backup buffer to the main temp buffer
            $tempBuffer = _.clone($backupBuffer);
            // empty the backup buffer till next use
            $backupBuffer.length = 0;

            that.$stillSending = false;

            // Wait before sending another request
            that._waitAndSend();
        }
    );

};

/**
 * Wait for N seconds before sending the buffer into the server
 *
 * @private
 */
Buffer.prototype._waitAndSend = function ()
{

    var that = this;

    // Wait before sending another request
    setTimeout(function ()
    {
        that.send();
    }, 10000);
};

/**
 * Return the buffer
 * It switches to both temp and backup buffer depending on the state
 *
 * @returns {Array}
 * @private
 */
Buffer.prototype._getBuffer = function ()
{

    // Return the buffer to use
    return (this.stillSending) ?
        $backupBuffer : $tempBuffer;
};

module.exports = Buffer;