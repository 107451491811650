"use strict";

var ga = window.ga;

function GoogleAnalyticsTracker(router)
{
    this.router = router;
}

GoogleAnalyticsTracker.prototype.trackPage = function ()
{
    ga('send', 'pageview', {'page': this.router.path()});
};

GoogleAnalyticsTracker.prototype.trackEvent = function (category, action)
{
    console.log('tracking event!');
    //ga('send', 'event', category, action);
    //ga('send', 'event', 'FAQ Button', 'toggle', 'FAQ Button on Contact Page');
};

module.exports = GoogleAnalyticsTracker;