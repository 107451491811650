"use strict";

var _ = require('lodash');

/*=============================== EXCEPTIONS ======================================*/

/**
 * Throws when there is no definition is found
 *
 * @param object
 * @constructor
 */
function NoObjectDefinitionFoundException(object)
{
    this.message = object + ' not found';
    this.name = 'NoObjectDefinitionFoundException';
}

/*================================ CLASS ==========================================*/

/**
 * Application container
 * This is the simplest implementation of a Dependency Injection framework
 * using an application config for wiring and dependency graph.
 *
 * @param config
 * @constructor
 */
function Container(config)
{
    this._config = config;
    this._container = {};
}

/**
 * Build the container and evaluate the
 * config to build the factory of wired objects
 *
 * @return self
 */
Container.prototype.build = function ()
{
    var container = this._container;

    // These are the keys of all the objects
    // defined in the config
    // the goal here is to discover all of the items
    // in this stack.
    this._referenceStack = _.keys(this._config);

    var key = null;
    // loop through the stack
    while (this._referenceStack.length > 0) {

        key = this._referenceStack.shift();
        container[key] = this._evaluate(this._config[key]);
    }

    return this;
};

/**
 * Get the object from the container
 *
 * @param key
 * @returns {*}
 */
Container.prototype.get = function (key)
{
    this._throwExceptionIfObjectIsNotFound(key);

    return this._container[key];
};


/*============================ PRIVATE METHODS ====================================*/

/**
 * Evaluate the value of the object and perform
 * the neccessary wiring and dependency injection
 *
 * @param value
 * @returns {*}
 * @private
 */
Container.prototype._evaluate = function (value, skipEvaluation)
{
    var that = this;
    var property = null;

    // If the value is not an object
    // just return it as is
    if (!_.isObject(value)) {

        // Check if it's a reference
        // property reference starts with $
        var isValueAReference = /^\$/.test(value);

        // Configuration wirings can be clone
        // for literal transfers.
        // property reference starts with #
        var isValueAClone = /^#/.test(value);
        var refName = '';

        // if the value is a reference but skip
        // evaluation flag is set, skip this else
        // get reference
        if (isValueAReference && !skipEvaluation) {
            refName = value.substring(1);

            // check if the object hasn't been evaluated yet
            // the object hasn't been instantiated yet
            var refIndex = _.indexOf(this._referenceStack, refName);
            if (refIndex !== -1) {

                // remove the element into the ref stack
                this._referenceStack.splice(refIndex, 1);

                var propertyValue = this._config[refName];

                // evaluate it and return the resulting object
                if (!skipEvaluation) {
                    propertyValue = this._evaluate(this._config[refName]);
                }

                this._container[refName] = propertyValue;
                return this._container[refName];
            }

            return this.get(refName);
        }

        // get value clone
        if (isValueAClone) {
            refName = value.substring(1);
            return this._config[refName];
        }

        return value;
    }

    // start evaluating the object
    // check if the object has a module definition
    if (value.hasOwnProperty('module') && !skipEvaluation) {

        var c = require(value.module);
        var instance = Object.create(c.prototype);
        var args = [];

        // USING CONSTRUCTOR
        // there is a constructor arguments to be passed
        if (value.hasOwnProperty('create')) {

            args = _.map(value.create, function (v)
            {
                return that._evaluate.call(that, v);
            });
        }

        c.apply(instance, args);

        // USING PROPERTIES
        // Setting the object using it's properties
        if (value.hasOwnProperty('properties')) {

            instance = _.assign(
                instance,
                this._evaluate(value.properties)
            );
        }

        // USING METHOD
        // Call the method
        if (value.hasOwnProperty('func')) {

            args = (value.func.hasOwnProperty('args')) ?
                this._evaluate(value.func.args) : [];

            instance[value.func.method]
                .apply(instance, args);
        }

        return instance;

    }

    // if the object has been evaluated already
    // just return the value
    if (!_.isPlainObject(value) && !_.isArray(value)) {
        return value;
    }

    var hasSkipIndicator, strippedProperty;

    for (property in value) {
        if (value.hasOwnProperty(property)) {
            hasSkipIndicator = /^\-\-/.test(property);

            if (hasSkipIndicator) {
                strippedProperty = property.substring(2);

                value[strippedProperty] = this._evaluate(value[property], true);
                _.unset(value, property);

            } else {
                value[property] = this._evaluate(value[property], skipEvaluation);
            }
        }
    }


    return value;
};

/**
 * Returns true if the object definition exists
 *
 * @param object
 * @returns {boolean}
 * @private
 */
Container.prototype._objectDefinitionExists = function (object)
{
    return (!_.isUndefined(this._container[object]));
};

/**
 * Throws exception if object definition is not found
 *
 * @param object
 * @private
 */
Container.prototype._throwExceptionIfObjectIsNotFound = function (object)
{
    if (!this._objectDefinitionExists(object)) {
        throw new NoObjectDefinitionFoundException(object);
    }
};

module.exports = Container;
