/**
 * Container wiring information
 *
 * @type {{}}
 */
module.exports = {

    messageFlasher: {
        module: 'message-flasher'
    },

    /* Router */
    router: {
        module: 'grapnel',
        create: [{hashBang: true, root: '/'}]
    },

    /*-----------------------------------------------------------------------------
     PAGE ROUTES
     -----------------------------------------------------------------------------*/
    /* Routes */
    routes: {
        '/*'        : 'catch-all',
        '/'         : 'home',
        '/project/*': 'project-view',
        '/about'    : 'about',
        '/contact'  : 'contact'

        //'/404'      : '404'
    },

    routeMapper: {
        module: 'util/route-mapper',
        create: [
            '$router',
            '$routes'
        ]
    },

    /* Use for handling project data */
    projectDataManager: {
        module    : 'project-data-manager',
        properties: {
            '--projects': window.projects
        }
    },

    request: {
        module: 'request',
        create: [window.REQUEST_BASE]
    },

    formDataSender: {
        module: 'form-data-sender',
        create: ['$request']
    },

    /*-----------------------------------------------------------------------------
     EVENT HANDLER
     -----------------------------------------------------------------------------*/
    events: {
        'view-project'  : 'view-project',
        'hide-message'  : 'hide-message',
        'toggle-faq'    : 'toggle-faq',
        'submit-inquiry': 'submit-inquiry',
        'click-track'   : 'button-click-tracking'
    },

    eventMapper: {
        module: 'util/event-mapper',
        create: ['$events']
    },

    /*-----------------------------------------------------------------------------
     ACTIVITY TRACKING
     -----------------------------------------------------------------------------*/

    tracker: {
        module: 'tracker/tracker',

        properties: {
            buffer   : '$buffer',
            session  : '$session',
            mouse    : '$mouse',
            analytics: '$analytics'
        }
    },

    analytics: {
        module: 'tracker/ga-tracker',
        create: ['$router']
    },

    /* Tracking session */
    session: {
        module: 'tracker/session'
    },

    /* Buffer for tracking events */
    buffer: {
        module: 'tracker/buffer',
        create: ['$session', '$request', '$router', false]
    },

    /* Mouse tracking */
    mouse: {
        module: 'tracker/mouse',
        create: ['$buffer']
    }
};