"use strict";

var _ = require('lodash');

/**
 * Project Data Manager
 * Handles formatting of project data for display
 *
 * @constructor
 */
function ProjectDataManager()
{
    this.projects = {};
    this.formatted = [];

    // project reference object
    this.ref = {};
}

/**
 * Get project by url
 *
 * @param url
 * @returns project
 */
ProjectDataManager.prototype.get = function (url)
{
    return this.ref[url];
};

/**
 * Return all the projects
 *
 * @returns {{}|*}
 */
ProjectDataManager.prototype.getProjects = function ()
{
    return this.formatted;
};

/**
 * Formats the raw json format into what the system
 * would need to render the projects into the view.
 *
 * @returns {Array}
 */
ProjectDataManager.prototype.format = function ()
{
    var formatted = [];
    var currentStack = null;

    var that = this;

    _.each(this.projects, function (project, namespace)
    {
        project.namespace = namespace;
        project.base = project.url;
        project.url = "project/" + project.url;
        project.hasDetails = project.hasOwnProperty("details");

        that.ref[project.base] = project;

        // if the type is set to full card view
        // just push it into the formatted array list
        if (project.type === 'full') {
            project.isFull = true;
            formatted.push(project);
        }

        if (project.type === 'half') {

            project.isFull = false;

            // if there is no stack defined
            // create a new one
            if (currentStack === null) {

                formatted.push({
                    "type": "stack",
                    items : []
                });

                currentStack = _.last(formatted);
            }

            if (currentStack.items.length <= 2) {
                currentStack.items.push(project);

                if (currentStack.items.length === 2) {
                    currentStack = null;
                }
            }
        }

    });

    this.formatted = formatted;
};


module.exports = ProjectDataManager;