"use strict";
var Meta = require('meta');
var Scroller = require('scroller');
var Wiper = require('wiper');
var $ = require('jquery');
var Tabs = require('tabs');


function AboutRouteHandler()
{

    var description = 'Having almost 7 years of experience in the industry, ' +
        'we’ve built the confidence and rapport to deliver the most demanding ' +
        'projects but still maintain the level of quality in every step of the way.';

    this._meta = {
        title      : ' - What we can do for you',
        description: description,
        url        : '/about'
    };
}

AboutRouteHandler.prototype.init = function ()
{
    Meta.set(this._meta);

    this.app.set('view', 'about')
        .then(this._onSet.bind(this));
};

AboutRouteHandler.prototype._onSet = function ()
{
    $(".about-href").on('click', function() {
        $('html, body').animate({
            scrollTop: $(".about-intro").offset().top - 110
        }, 1500);
    });

    $(".clients-href").on('click', function() {
        $('html, body').animate({
            scrollTop: $(".clients").offset().top -180
        }, 1500);
    });

    $(".services-href").on('click', function() {
        $('html, body').animate({
            scrollTop: $("#service-extensions").offset().top - 180
        }, 1500);
    });

    $(".nav > li:first-of-type > a").addClass("-active");
    $(".nav > li:last-of-type > a").removeClass("-active");

    var tabs = new Tabs({
        ontabclicked: this._onTabClicked.bind(this)
    });

    tabs.init();

    // Make sure to make the first project visible
    Scroller.scrollTo(0);

    var wiper = new Wiper();
    wiper.wipeOut();
};

AboutRouteHandler.prototype._onTabClicked = function (tab, target)
{

    window.ga('send', 'event',
        'About Us Tabs', 'click',
        $(tab).text());

};

module.exports = AboutRouteHandler;