var $ = require('jquery');

/**
 * Submit Button wrapper
 *
 * @constructor
 */
function SubmitButton()
{
    "use strict";

    // Create a new instance if no instance is found
    if ($('.submit-wrapper').length === 0) {

        this.$el = this.$button = $('[type=submit]');
        this._renderSubmit();

    } else {
        // else return the current instance
        this.$el = $('.submit-wrapper');
        this.$button = $('[type=submit]');
    }

}


SubmitButton.prototype.clicked = function ()
{
    "use strict";

    this.$el.addClass('-is-animated -is-loading');
};

SubmitButton.prototype.done = function (onComplete)
{
    "use strict";

    var el = this.$el;

    // delay before marking the item as done
    setTimeout(function ()
    {
        el.addClass('-done');

        // before clearing out the animation
        setTimeout(function ()
        {
            el.removeClass('-is-animated -is-loading -done');

            // after animating
            // call the on complete callback
            if (onComplete) {
                onComplete();
            }

        }, 2200);

    }, 1000);
};

/**
 * Renders submit button
 */
SubmitButton.prototype._renderSubmit = function ()
{
    "use strict";

    var $submit = this.$el;
    $submit.wrap('<div class="submit-wrapper" />');

    var $submitWrapper = $submit.parent();

    // Add dots for loading animation
    var dots = '';
    for (var i = 0; i <= 2; i++) {
        dots += '<span class="dot animated bounce" />';
    }

    $submitWrapper
        .prepend('<div class="loading-indicator">' + dots + '</div><div class="wiper" />');


    // please take note the switch between
    // the change of assignment
    this.$el = $submit.parent();

};

module.exports = SubmitButton;