"use strict";
var $ = require('jquery');
var _ = require('lodash');
var Meta = require('meta');

var Wiper = require('wiper');
var Scroller = require('scroller');
var ScrollerHandler = require('scroller-handler');

/**
 * Route handler for /project/*
 *
 * @constructor
 */
function ProjectViewRouteHandler()
{
}

/**
 * Initializes page
 *
 * @private
 */
ProjectViewRouteHandler.prototype.init = function ()
{
    var url = this.req.matches[0];
    var project = this.app.projectDataManager.get(url);

    this._meta = {
        title      : project.name,
        description: project.details.summary.preamble,
        url        : '/' + project.url,
        image      : 'data/projects/' + project.namespace + '/' + project.details.cover
    };

    Meta.set(this._meta);

    this.project = project;
    this.app
        .set('view', '')
        .then(this._wait.bind(this));
};

ProjectViewRouteHandler.prototype._wait = function ()
{
    setTimeout(this._loadProject.bind(this), 1);
};

ProjectViewRouteHandler.prototype._loadProject = function ()
{
    var project = this.project;
    var suggestedText = [
        "Go check out these projects!",
        "You might want to check these out.",
        "Cool! Now go check these out!",
        "Still wanting more?"
    ];

    /*------------------------------------------------------------
     Detects what image is visible to the user
     when scrolling through a project page
     ------------------------------------------------------------*/

    var currentImageOnView = '';
    var tracker = this.tracker;
    this.projectImageLogHandler = new ScrollerHandler({
        el    : '.media.image',
        handle: function (el)
        {
            var img = $('img', el).attr('src');
            if (currentImageOnView !== img) {
                currentImageOnView = img;

                tracker.track({v: currentImageOnView}, '_e');
            }
        }
    });

    /*------------------------------------------------------------
     Detects how far the cards from the top
     use for handling what segments should be shown to the
     user when scrolling.

     Use for transitioning cards on enter
     ------------------------------------------------------------*/
    this.cardScrollEntryHandler = new ScrollerHandler({
        el    : '.card',
        handle: function (el)
        {
            el.addClass('enter');
        },
        // show after 90% of the screen
        offset: 0.9
    });


    var projects = window.projects;
    this.app.set('project', project);

    var suggested = [];
    _.each(project.details.suggested, function (p)
    {
        p = _.cloneDeep(projects[p]);

        if (project.details.suggested.length > 1) {
            p.type = 'half';
        }

        p.suggested = true;
        p.suggested_source = project.name;

        suggested.push(p);
    });

    this.app.set(
        'suggestedText',
        suggestedText[_.random(0, suggestedText.length - 1)]
    );

    this.app.set(
        'hasSuggestedProjects',
        (!_.isUndefined(project.details.suggested))
    );
    this.app.set('suggestedProjects', suggested);
    this.app.set('view', 'project')
        .then(this._onSet.bind(this));
};

/*------------------------------------------------------------
 EVENT HANDLER
 ------------------------------------------------------------*/

ProjectViewRouteHandler.prototype._handleEvents = function ()
{
    /*------------------------------------------------------------
     Handles scroll event
     ------------------------------------------------------------*/
    Scroller.scroll({
        onscroll: this._onScroll.bind(this)
    });
};

/*------------------------------------------------------------
 OnScroll Event
 ------------------------------------------------------------*/
ProjectViewRouteHandler.prototype._onScroll = function (scrollPosition, priority)
{

    // Make this scrolling event high priority
    if (priority === 'high') {

        this.cardScrollEntryHandler.checkHit(scrollPosition);
        this.projectImageLogHandler.checkHit(scrollPosition);
        this.tracker.track({y: scrollPosition}, '_s');

    }
    
};

/**
 * Called when the page is completely rendered
 *
 * @private
 */
ProjectViewRouteHandler.prototype._onSet = function ()
{
    this._handleEvents();

    // Make sure to make the first project visible
    Scroller.scrollTo(0);

    var wiper = new Wiper();
    wiper.wipeOut();
};

module.exports = ProjectViewRouteHandler;