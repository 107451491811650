"use strict";

module.exports = function (data, url, container)
{
    var router = container.get('router');

    if (data.context.hasOwnProperty("hasDetails") &&
        data.context.hasDetails === true) {

        // track whether the source is from a suggested project
        // displayed below
        if (data.context.suggested) {

            window.ga('send', 'event',
                'View Project: ' + data.context.name,
                'click_from_suggested',
                'View Project from: ' + data.context.suggested_source);

        } else {

            window.ga('send', 'event',
                'View Project: ' + data.context.name,
                'click_from_home',
                'View Project from: Home');

        }


        router.navigate('/' + url);

    }
};