"use strict";
/**
 * Message flasher for notification
 *
 * @param app
 * @constructor
 */
function MessageFlasher()
{
    this.app = null;
}

/**
 * Initializes the message flasher
 *
 * @param app
 */
MessageFlasher.prototype.init = function (app)
{
    this.app = app;
};

/**
 * Show the message to the user
 *
 * @param message
 */
MessageFlasher.prototype.flash = function (message)
{
    this._set(message, true);
};

/**
 * Clear the message
 */
MessageFlasher.prototype.clear = function ()
{
    this._set(null, false);
};

/**
 * Sets the message
 *
 * @param message
 * @param isVisible
 * @private
 */
MessageFlasher.prototype._set = function (message, isVisible)
{
    this.app.set('flashMessage', isVisible);
    this.app.set('message', message);
};


module.exports = MessageFlasher;
