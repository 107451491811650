"use strict";
var $ = require('jquery');

$(function() { 
    scrollToProjects();
    scrollAboutUs();
})

function scrollToProjects() {
    $(".project-experiences").on('click', function() {
        $('html, body').animate({
            scrollTop: $(".projects").offset().top
        }, 1500);
    });
}

function scrollAboutUs() {
    $(".about-href").on('click', function() {
        $('html, body').animate({
            scrollTop: $(".about-intro").offset().top
        }, 1500);
    });

    $(".services-href").on('click', function() {
        $('html, body').animate({
            scrollTop: $("#service-extensions").offset().top
        }, 1500);
    });
}

