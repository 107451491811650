var $ = require('jquery');
var _ = require('lodash');

/**
 * Request object that encapsulates various
 * server based operation.
 *
 * This simplifies access to the server api requests
 *
 * @param baseUri
 * @constructor
 */
function Request(baseUri)
{
    "use strict";

    this.$uri = baseUri;
}

/**
 * Get all projects
 *
 * @param cb
 */
Request.prototype.getProjects = function (cb)
{
    "use strict";

    this._get(this.$uri + '/api/projects', {}, cb);
};

/**
 * Submit data to server
 *
 * @param param
 * @param data
 * @param cb
 */
Request.prototype.sendToServer = function (uri, data, cb)
{
    "use strict";
    this._post(this.$uri + '?_l=' + uri, data, cb);
};

/*------------------------------------------------------------*/

/**
 * Return the analytics data
 *
 * @param param
 * @param cb
 */
Request.prototype.getAnalyticsData = function (param, cb)
{
    "use strict";
    param._l = 'analytics';
    this._get(param, cb);
};

/*------------------------------------------------------------*/

/**
 * Send a get request
 *
 * @param param
 * @param cb
 * @private
 */
Request.prototype._get = function (uri, param, cb)
{
    "use strict";
    $.get(uri, param, cb);
};


/**
 * Send a post request
 * @param param
 * @param data
 * @param cb
 * @private
 */
Request.prototype._post = function (uri, data, cb)
{
    "use strict";
    $.post(uri, data, cb);

};

module.exports = Request;