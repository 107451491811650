var _ = require('lodash');
var $ = require('jquery');

var $request = null;
var $app = null;
var $router = null;

function Analytics(request, app, router)
{
    "use strict";

    $request = request;
    $app = app;
    $router = router;

    this.ClickMapper = new ClickMapper();
}

/*--------------------------------------------------
 Click Mapper
 *--------------------------------------------------*/
function ClickMapper()
{
    "use strict";

    var $window = $(window);
    var that = this;

    $router.on('hashchange', function() {
        that.map($router.path(), $window.width());
    });
}

ClickMapper.prototype.map = function (page, width)
{
    "use strict";

    var that = this;

    $request.getAnalyticsData({
        info : 'click-map',
        type : '_c,_m',
        page : page,
        width: width
    }, function (response)
    {
        // remove all html data
        response = _.map(response, function (data)
        {
            data.data = JSON.parse(data.data);
            data.hasCoordinates = data.data.hasOwnProperty('x');
            data.size = data.intensity * 5;

            return data;
        });

        $app.set('analytics.clickmap', response);

    });
};


module.exports = Analytics;