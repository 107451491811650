"use strict";

var $ = require('jquery');
var _ = require('lodash');

/**
 * Tracks mouse related movements & events
 *
 * @param buffer
 * @constructor
 */
function Mouse(buffer)
{
    this.buffer = buffer;
}

Mouse.prototype.track = function ()
{
    this.x = 0;
    this.y = 0;

    $('body')
        .on('mousemove', _.debounce(this._onMouseMove.bind(this), 100))
        .on('click', this._onClick.bind(this));
};

/*-----------------------------------------------------------------------------
 EVENT HANDLER
 -----------------------------------------------------------------------------*/
/* On Mouse move */
Mouse.prototype._onMouseMove = function (e)
{
    this.x = e.pageX;
    this.y = e.pageY;

    this._recordMousePosition('move');
};

/* On Mouse click */
Mouse.prototype._onClick = function ()
{
    this._recordMousePosition('click');
};

/**
 * Record the position of the mouse
 *
 * @param type
 * @param options
 * @private
 */
Mouse.prototype._recordMousePosition = function (type, options)
{
    var t = {
        'move' : '_m',
        'click': '_c'
    };

    var data = _.merge(this._getPosition(), options)
    this.buffer.add(data, t[type]);
};

/**
 * Returns the x & y position of the mouse
 *
 * @returns {{x: *, y: *}}
 * @private
 */
Mouse.prototype._getPosition = function ()
{
    return {
        x: this.x, y: this.y
    };
};

module.exports = Mouse;