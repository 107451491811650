var _ = require('lodash');
var $ = require('jquery');

var SubmitButton = require('submit-button');
var submitButton = null;

/**
 * Responsible for sending data to the server
 *
 * @param request
 * @constructor
 */
function FormDataSender(request)
{
    "use strict";
    this._get = request;
}

/**
 * Submit
 *
 * @param fields
 * @param onComplete
 */
FormDataSender.prototype.submit = function (fields, onComplete)
{
    "use strict";

    // get submit button
    submitButton = new SubmitButton();

    this.$onComplete = onComplete;
    this.$visibleFormFields = fields;

    // validate the form
    var __ret = this.$validate(fields);

    var data = __ret.data;
    var errors = __ret.errors;

    if (errors.length > 0) {

        // mark fields with errors
        errors = _.uniq(errors);

        _.each(errors, function (field)
        {
            field.parent()
                .addClass('error');
        });

    } else {

        // Additional meta data to be sent to the server
        data.meta = {

            // previous page before the user
            // clicks the contact us button
            referrer      : '',

            //the last active segment seen by the user
            segment       : '',

            // number of clicks before deciding to
            // send an inquiry
            numberOfClicks: '',

            // send the session history
            sessionHistory: {}
        };

        // send this data to the server
        submitButton.clicked();
        this.$submitToServer(data);

    }
};

/**
 * Validates the field for any input errors
 *
 * @param fields
 * @returns {{data: {fields: {}}, errors: Array}}
 */
FormDataSender.prototype.$validate = function (fields)
{
    "use strict";

    // data to be sent to the server
    var data = {fields: {}};
    var errors = [];

    _.each(fields, function (field)
    {
        var $field = $(field);
        var name = $field.attr('name');
        var value = $field.val();

        // clear any errors first
        $field.parent().removeClass('error');

        // if the field is empty
        // add it as error
        if (value.trim().length === 0) {
            errors.push($field);
        }

        switch (name) {

            // make sure the email is valid
            case 'email':
                var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
                if (!re.test(value)) {
                    errors.push($field);
                }
                break;

            case 'name':
                var re = /^[a-zA-Z ]+$/;
                if (!re.test(value)) {
                    errors.push($field);
                }
                break;

            case 'contact':
                var re = /^[0-9]*$/;
                if (!re.test(value)) {
                    errors.push($field);
                }
                break;
            default:
                break;
        }

        // store the data to be sent to the server
        data.fields[name] = value;
    });

    return {
        data  : data,
        errors: errors
    };
};

/**
 * Submits the data to the server
 *
 * @param data
 */
FormDataSender.prototype.$submitToServer = function (data)
{
    "use strict";
    var onComplete = this.$onComplete;

    this._get
        .sendToServer(
        'inquiry',
        data,
        onComplete
    );
};


module.exports = FormDataSender;