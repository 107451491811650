"use strict";
var Meta = require('meta');
var Scroller = require('scroller');
var Wiper = require('wiper');
var Tabs = require('tabs');
var FormRenderer = require('form-renderer');
var $ = require('jquery');

function ContactRouteHandler()
{
    this._meta = {
        title      : ' - Get in touch',
        description: 'Give us a call, let\'s talk!',
        url        : '/contact'
    };
}

ContactRouteHandler.prototype.init = function ()
{
    Meta.set(this._meta);

    this.app.set('view', 'contact')
        .then(this._onSet.bind(this));
};

ContactRouteHandler.prototype._onSet = function ()
{
    /*------------------------------------------------------------
     Initializes the interface
     ------------------------------------------------------------*/
    this.app.formRenderer = new FormRenderer();
    this.app.formRenderer.render();

    $('.services-dropdown-wrapper').on("click", function() {
        $('.services-dropdown').toggleClass('open');
    })

    for (const option of document.querySelectorAll(".services-option")) {
        option.addEventListener('click', function() {
            if (!this.classList.contains('selected')) {
                this.parentNode.querySelector('.services-option.selected').classList.remove('selected');
                this.classList.add('selected');
                this.closest('.services-dropdown').querySelector('.services-dropdown-trigger span').textContent = this.textContent;
                $('#service-type').val(this.textContent);
            }
        })
    }

    $(".nav > li:first-of-type > a").removeClass("-active");
    $(".nav > li:last-of-type > a").addClass("-active");

    var tabs = new Tabs({
        ontabclicked: this._onTabClicked.bind(this)
    });

    tabs.init();

    var wiper = new Wiper();
    wiper.wipeOut();

    Scroller.scrollTo(0);

};


ContactRouteHandler.prototype._onTabClicked = function (tab, target)
{
    // re-order form tab index
    // and focus on the first field
    this.app
        .formRenderer
        .getActiveFields('.pane.' + target)
        .reOrderTabIndex()
        .clearErrors();

    window.ga('send', 'event',
        'Contact Page Tabs', 'click',
        $(tab).text());

};

module.exports = ContactRouteHandler;