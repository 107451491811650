/* --------------------------------------------
 * Declare all Routes
 * --------------------------------------------*/
module.exports = {

    '/*'        : 'catch-all',
    '/'         : 'home',
    '/project/*': 'project-view',
    '/about'    : 'about',
    '/contact'  : 'contact',
    '/404'      : '404'
};