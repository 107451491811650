var $ = require('jquery');

/* From Modernizr */
function _whichTransitionEvent(el)
{
    "use strict";

    var t;
    var transitions = {
        'transition'      : 'transitionend',
        'OTransition'     : 'oTransitionEnd',
        'MozTransition'   : 'transitionend',
        'WebkitTransition': 'webkitTransitionEnd'
    };

    for (t in transitions) {
        if (el[0].style[t] !== undefined) {
            return transitions[t];
        }
    }
}

function Wiper()
{
    "use strict";
    this.$wiper = $('.page-wiper');
}

Wiper.prototype.wipeIn = function ()
{
    "use strict";
    this.$wiper.addClass('-wipe-in');
};

Wiper.prototype.wipeOut = function ()
{
    "use strict";
    var $wiper = this.$wiper;
    var $wiperBlock = $('.wiper', this.$wiper);

    $wiper.addClass('-wipe-out');

    setTimeout(function ()
    {
        $wiper.addClass('visuallyhidden');
    }, 650);

};

module.exports = Wiper;