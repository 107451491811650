var $ = require('jquery');
var _ = require('lodash');
var UIControls = require('ui-controls');

var ui = new UIControls();

function Tabs(options)
{
    "use strict";

    this.$tabs = $('.tab');
    this.$options = _.merge(
        {
            ontabclicked: function ()
            {
                return;
            }
        },
        options
    );

    // bind the tab click
    this.tabClicked = this.$options.ontabclicked;
}

Tabs.prototype.init = function ()
{
    "use strict";
    var that = this;

    this.$tabs.on('click', function ()
    {
        that._select($(this), true);
    });

    // Select the first tab
    this._select(_.first(this.$tabs), false);
};

Tabs.prototype._select = function (tab, firedDuringInitialization)
{
    "use strict";
    tab = $(tab);

    var target = tab.data('target');

    this.$highlight(target);
    this.$makeBodyVisible(target);


    this.tabClicked(tab, target, firedDuringInitialization);

};

Tabs.prototype.$highlight = function (target)
{
    "use strict";

    ui.selectExcept(
        '.tab[data-target="' + target + '"]',
        '.tab[data-target!="' + target + '"]',
        '-active'
    );

};

Tabs.prototype.$makeBodyVisible = function (target)
{
    "use strict";

    ui.selectExcept(
        '.pane',
        '.pane.' + target,
        'visuallyhidden'
    );
};

module.exports = Tabs;