"use strict";

var $ = require('jquery');
var _ = require('lodash');

function CheckScroll() {}

CheckScroll.prototype.detect = function() {
    // var ssoptions = {
    //     mode: "set", // "vp", "set"
    //     autoHash: false,
    //     sectionScroll: true,
    //     initialScroll: true,
    //     keepHistory: false,
    //     sectionWrapperSelector: ".projects",
    //     sectionClass: "card-sec",
    //     animationSpeed: 300,
    //     headerHash: "portfolio-container",
    //     breakpoint: 999999,
    //     eventEmitter: null,
    //     dynamicHeight: false
    // };

    var ssoptions = {};


    if ($('#portfolio-container').find('.projects').length > 0) {
        var ssoptions = {
            mode: "set", // "vp", "set"
            autoHash: false,
            sectionScroll: true,
            initialScroll: true,
            keepHistory: false,
            sectionWrapperSelector: ".projects",
            sectionClass: "card-sec",
            animationSpeed: 300,
            headerHash: "portfolio-container",
            breakpoint: null,
            eventEmitter: null,
            dynamicHeight: false
        };
    } else {
        var ssoptions = {
            mode: "set", // "vp", "set"
            autoHash: false,
            sectionScroll: true,
            initialScroll: true,
            keepHistory: false,
            sectionWrapperSelector: "#portfolio-container",
            sectionClass: "card-sec",
            animationSpeed: 300,
            headerHash: "portfolio-container",
            breakpoint: 999999,
            eventEmitter: null,
            dynamicHeight: false
        };
    }

    return {
        'scroll': ssoptions
    };
};

module.exports = CheckScroll;