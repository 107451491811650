var $ = require('jquery');

/**
 * Controls use for common ui manipulations
 *
 * @constructor
 */
function UIControls()
{
    "use strict";
}

/**
 * Show the target view
 * @param view
 */
UIControls.prototype.show = function (view)
{
    "use strict";

    // hide everything except the target view
    this.selectExcept(
        'section',
        'section.' + view,
        'visuallyhidden'
    );

    $('body').scrollTop(0);
};

/**
 * Select common elements except the target
 *
 * @param selected
 * @param except
 * @param toggleClass
 */
UIControls.prototype.selectExcept = function (selected, except, toggleClass)
{
    "use strict";
    $(selected).addClass(toggleClass);
    $(except).removeClass(toggleClass);

};

/**
 * Toggle's class visibility
 *
 * @param target
 */
UIControls.prototype.toggleVisibility = function (target)
{
    "use strict";

    $(target).toggleClass('visuallyhidden');
};

module.exports = UIControls;
