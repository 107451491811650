"use strict";
module.exports = function (context, data)
{
    var details = data.split(',');

    var qualifier = details[0];

    // the button is clicked from the project view
    if (qualifier === 'project-view') {
        window.ga('send', 'event',
            'Get In Touch Button from Project View',
            'click',
            'Source: ' + details[1]);
    }

    // Navigation bar clicked
    else if (qualifier === 'navi') {

        var page = {
            'about'  : 'About',
            'contact': 'Get In Touch'
        };

        page = page[details[1]];

        window.ga('send', 'event',
            'Click from Navigation Bar: ' + page,
            'nav:click',
            page + ' from Nav Bar');
    }

    // Contact button click from About Us Page
    else if (qualifier === 'about') {
        window.ga('send', 'event',
            'Get In Touch Button from About Page',
            'click',
            'Source: About');
    }

    // About button click from Hero section
    else if (qualifier === 'about-from-hero') {
        window.ga('send', 'event',
            'About Page from Hero',
            'click',
            'Source: Hero');
    }

};