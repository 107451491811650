"use strict";

var _ = require('lodash');

/**
 * Handles DOM related event mappings
 *
 * @param events
 * @constructor
 */
function EventMapper(events)
{
    this.events = events;
}

/**
 * Map events into their proper handler
 *
 * @param container
 * @param app
 */
EventMapper.prototype.mapEvents = function (container, app)
{
    this.app = app;
    this.container = container;

    _.each(this.events, this._iterateEventHandler.bind(this));

    // map those events into the app space
    app.on(this.events);
};

/**
 * Iteration handler
 *
 * @param handler
 * @param event
 * @param mapping
 * @private
 */
EventMapper.prototype._iterateEventHandler = function (handler, event, mapping)
{
    var app = this.app;
    var container = this.container;

    mapping[event] = function ()
    {
        var h = require('event-handler/' + handler);

        h.apply(
            app,
            _.flatten(
                _.concat(arguments, container)
            )
        );
    };
};

module.exports = EventMapper;